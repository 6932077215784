import { useEffect } from 'react';
import { string } from 'prop-types';

import { loadable } from 'nordic/lazy';

import { setHistoryListener } from '../../../../components/context/location-search';
import { PI, CATEGORY, RESCUE } from './main';

const RescueMobile = loadable(async () => import('../views/rescue/rescue.mobile'));
const SearchMobile = loadable(async () => import('../views/main/main.mobile'));
const SearchMobilePI = loadable(async () => import('../views/main/main.mobile.pi'));
const SearchMobileCAT = loadable(async () => import('../views/categories/cat.mobile'));

export const MobileView = (props) => {
  const { componentMapId, currentLayout } = props;

  useEffect(() => {
    setHistoryListener(currentLayout);
  });

  switch (componentMapId) {
    case RESCUE:
      return <RescueMobile {...props} />;
    case PI:
      return <SearchMobilePI {...props} />;
    case CATEGORY:
      return <SearchMobileCAT {...props} />;

    default:
      return <SearchMobile {...props} />;
  }
};

MobileView.propTypes = {
  componentMapId: string,
  currentLayout: string,
};
