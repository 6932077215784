import { MobileView } from '../../pages/search/components/map/main.mobile';
import { renderView } from '../../utils/render';

const View = (props) => renderView(MobileView, props);

export { getServerSideProps, setPageSettings } from '../../pages/search/routes/main';

export const hydrate = 'hydrateRoot';

export default View;
